import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  styled,
  IconButton,
} from "@material-ui/core";

import EmailAccountRegistrationController, {Props,configJSON} from "./EmailAccountRegistrationController";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { carousel_display, sleek } from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
     <>
        <SignupContainer>
            <Box className="leftContainer">
              <img src={carousel_display.default} style={{height: "100%", width: "100%"}}/>
            </Box>
            <Box className="rightContainer">
                <img src={sleek.default} style={{ width: "81px", height: "27px", marginLeft: "auto"}}/>
                <Box className="formContainer">
                    <p className="formTitle">Create password</p>
                    <Formik
                        initialValues={{
                        passcodeInputField: this.state.password,
                        confirmPasscodeInputField: this.state.reTypePassword,
                        email: this.state.email,
                        }}
                        data-test-id="formik"
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting, setErrors }) => {  
                          const errors:{ email?:string, passcodeInputField?:string, confirmPasscodeInputField?: string, passcodeDoesNotMatch?: string} = {};
          
                          if (!values.email) {
                            errors.email = configJSON.emailRequired;
                          } 
                          if (!values.passcodeInputField) {
                            errors.passcodeInputField = configJSON.PasscodeRequired;
                          }
                          if (!values.confirmPasscodeInputField){
                            errors.confirmPasscodeInputField = configJSON.confirmPasswordRequired;
                          }
                          
                          if (values.passcodeInputField !== values.confirmPasscodeInputField && Object.keys(errors).length === 0){
                            errors.passcodeDoesNotMatch = configJSON.PasscodeMustMatch;
                          }

                          if (!Yup.string().email().isValidSync(values.email) && Object.keys(errors).length === 0) {
                            errors.email = configJSON.invalidEmail;
                          }
          
                          if (Object.keys(errors).length > 0) {
                            setErrors(errors);
                            setSubmitting(false);
                            return ;
                          }    
          
                          this.doEmailSignUp();
                        }}
                    >
                    {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <Box>
                            <Box className="inputField" style={{boxShadow: errors && errors.email && submitCount > 0 || this.state.signupFailed ? "0px 0px 3px 0px #C5475B" : ''}}>
                            <p className="inputTitle">Email</p>
                            <InputField
                                name="email"
                                fullWidth={true}
                                value={values.email}
                                disableUnderline={true}
                                data-test-id="emailField"
                                onChange={
                                    (e:{target:{value:string}}) => {
                                        this.setEmail(e.target.value); 
                                        setFieldValue("email", e.target.value); setErrors({...errors, email:""}); this.clearLoginStatus()
                                    }}
                            />
                            </Box>
                            <Box className="inputField" style={{ paddingRight: "50px", marginTop: "20px", boxShadow: errors && errors.passcodeInputField || this.state.signupFailed || (errors as any).passcodeDoesNotMatch ? "0px 0px 3px 0px #C5475B" : ''}}>
                                <p className="inputTitle">Password</p>
                                <InputField
                                    data-test-id="passwordField"
                                    name="passcodeInputField" fullWidth={true}
                                    value={values.passcodeInputField} disableUnderline={true}
                                    type={this.state.enablePasswordField ? "password" : "text"}
                                    onChange={(e:{target:{value:string}}) => {this.setPassword(e.target.value); setFieldValue("passcodeInputField", e.target.value); this.clearLoginStatus(); setErrors({...errors, passcodeInputField:"", passcodeDoesNotMatch:""} as any)}}
                                />
                                <IconButton
                                    edge="end"
                                    data-test-id="hidePassword"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    style={{ position: "absolute", top: 0, bottom: 0, right: "14px", cursor: "pointer"}}
                                >
                                    {!this.state.enablePasswordField ? ( <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                                    ) : ( <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/> )}
                                </IconButton>
                            </Box>
                            <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.confirmPasscodeInputField && submitCount > 0 || this.state.signupFailed || (errors as any).passcodeDoesNotMatch ? "0px 0px 3px 0px #C5475B" : '', marginTop: "20px", }}>
                                <p className="inputTitle">Confirm password</p>
                                <InputField
                                    disableUnderline={true}
                                    fullWidth={true} value={values.confirmPasscodeInputField}
                                    name="confirmPasscodeInputField" data-test-id="confirmPasswordField"
                                    type={this.state.enableReTypePasswordField ? "password" : "text"}
                                    onChange={(e:{target:{value:string}}) => {this.setConfirmPassword(e.target.value); setFieldValue("confirmPasscodeInputField", e.target.value); this.clearLoginStatus(); setErrors({...errors, confirmPasscodeInputField:"", passcodeDoesNotMatch:""} as any)}}
                                />
                                <IconButton
                                    edge="end" data-test-id="hideConfirmPassword"
                                    aria-label="toggle password visibility" onClick={this.handleClickShowConfirmPassword}
                                    style={{ 
                                        top: 0, bottom: 0, 
                                        position: "absolute", right: "14px", 
                                        cursor: "pointer"}}
                                >
                                    {!this.state.enableReTypePasswordField ? (
                                        <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                                    ) : (
                                        <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                          <Box style={{width: "100%", height:"87px", display:"flex", flexDirection:"column-reverse"}}>
                            {( errors && 
                            (errors.email && errors.email.includes("required") || 
                            errors.passcodeInputField && errors.passcodeInputField.includes("required") || 
                            errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required"))) && submitCount > 0 && 
                            <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                            <p>{errors && 
                                [ errors.email && errors.email.includes("required"),
                                errors.passcodeInputField && errors.passcodeInputField.includes("required"),
                                errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required")
                                ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
                            {errors && errors.email && errors.email.includes("Invalid email format") && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid credentials.</p></div>}
                            {errors && ((errors as any).passcodeDoesNotMatch && (errors as any).passcodeDoesNotMatch.includes("PasscodeInputField must match")) && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Passwords do not match.</p></div>}
                            {this.state.signupFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.signupFailedMessage}</p></div>}
                            </Box>
                            <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Continue</Button>
                            <Box style={{height: "80px", display:"flex"}}>
                              <p style={{margin: 0,
                                  color: "#BABDD4",
                                  fontWeight: 500, marginTop: "20px",
                                  fontSize: "13px", lineHeight: "10px",
                                  textAlign: "left", cursor: "pointer",
                                  letterSpacing: "-0.10000000149011612px",fontFamily: "Avenir Next Medium",
                                  }}>By proceeding, you agree to our <span style={{
                                  fontSize: "13px", fontWeight: 500,
                                  textAlign: "left", color: "#72749D", letterSpacing: "-0.25px"
                                  }}>Terms and Conditions.</span></p>
                            </Box>
                        </Box>
                    </form>
                    )}
                    </Formik>
                </Box>
            </Box>
        </SignupContainer>
     </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 
const SignupContainer = styled(Box)({
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
    '& .rightContainer':{
      display: "flex",
      padding: "26px",
      flexDirection: "column",
    },
    '& .leftContainer':{
      width: "100%",
      height: "100%",
    },
    '& .formContainer':{
      flexGrow: 1,
      width: "418px", 
      display: "flex",
      marginTop: "47px",
      marginLeft: "18px",
      marginRight: "50px",
      marginBottom: "69px",
      borderRadius: "30px",
      flexDirection:"column",
      boxSizing: "border-box",
      padding: "30px 40px 25px",
      backgroundColor: "#ffffff",
      boxShadow: "-2px 10px 19px 0px #D5D5DC, 2px 0px 16px 0px #EEEEF2",
    },
    '& .sleekText':{
      margin: "0",
      textAlign: "end",
    },
    '& .form':{
      flexGrow: 1,
      display: "flex",
      marginBottom: "0",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    '& .formTitle':{
      color: "#4F506D",
      fontSize: "36px",
      fontWeight: 275,
      textAlign: "left",
      marginTop: "31px",
      marginBottom: "57px",
      lineHeight: "49.18px",
      fontFamily: "Avenir Next Ultra Light"
    },
    '& .inputField':{
        padding: "6px 16px",
        position: "relative",
        borderRadius: "20px",
        boxShadow: "0px 1px 3px 0px #72749D4F",
      '& .MuiSvgIcon-root':{
        height: "18px",
        width: "18px",
      },
      '& .MuiIconButton-root':{
        marginTop: "auto",
        height: "fit-content",
        marginBottom: "auto"
      }
    },
    '& .inputTitle':{
      margin: 0,
      fontWeight: 300,
      fontSize: "12px",
      textAlign: "left",
      color: "#ADB1C8",
      lineHeight: "22px",
      fontFamily: "Avenir Light"
    },
    '& .signButton':{ 
      width: "100%", 
      padding: "16px",
      color: "#FFFFFF",
      fontSize: "18px",
      cursor: "pointer",
      lineHeight: "24px",
      textAlign: "center",
      borderRadius: "12px", 
      fontFamily: "Avenir Next Regular",
      fontWeight: "350px",
      textTransform: "none", 
      backgroundColor: "#4F506D",
      '& .MuiButton-root':{
        fontFamily: "inherit"
      }
    },
    '& .rotated-icon': {
      transform: "rotateY(180deg)"
    },
    '& .errorMessage':{
      display: "flex",
      fontSize: "12px",
      fontWeight: 400,
      color: "#C5475B",
      textAlign: "left",
      marginRight: "auto",
      marginBottom: "16px",
      fontFamily: "Avenir Next Regular",
      '& p':{
        margin: 0,
        height: "14px",
        marginLeft: "5px",
      },
      '& .MuiSvgIcon-root':{
        width: "16px",
        height: "14px",
      }
    },
    '& .newUser':{
      margin: "0",
      fontSize: "16px",
      fontWeight: 300,
      color: "#595959",
      cursor: "pointer",
      lineHeight: "19px",
      textAlign: "center",
      letterSpacing: "0.10000000149011612px",
      '& span':{
        color: "#000000",
        fontWeight: 500
      }
    }
  })
  
  const InputField = styled(Input)({
      padding: "0",
      margin: "0",
      border: "none",
      width: "100%",
      borderRadius: "20px",
      backgroundColor: "#FFFFFF",
      fontFamily: "Avenir Light",
      fontSize: "16px",
      color: "#4F506D",
    '& .MuiInputBase-input':{
      padding: 0,
      marginBottom: "1px"
    }
  })

// Customizable Area End