import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  styled,
  IconButton,
} from "@material-ui/core";

import ForgotPasswordController, {Props,configJSON} from "./ForgotPasswordController";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { carousel_display, sleek } from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
    async componentDidMount() {
      this.checkUrlForResetPassword()
    }
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
     <>
      <SignupContainer>
        <Box className="leftContainer">
          <img src={carousel_display.default} style={{height: "100%", width: "100%"}}/>
        </Box>
        <Box className="rightContainer">
          <img src={sleek.default} style={{ width: "81px", height: "27px", marginLeft: "auto"}}/>
          <Box className="formContainer">
            <p className="formTitle">Forgot password</p>
            { !this.state.enableResetPassword &&
            <Formik
            initialValues={{
              email: this.state.email,
            }}
            data-test-id="formikEmail"
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, { setSubmitting, setErrors }) => {  
              const errors:{ email?:string} = {};

              if (!values.email) {
                errors.email = configJSON.emailRequired;
              } 
              else if (!Yup.string().email().isValidSync(values.email)) {
                errors.email = configJSON.invalidEmail;
              }

              if (Object.keys(errors).length > 0) {
                setErrors(errors);
                setSubmitting(false);
                return ;
              }    

              this.emailVerificationHandler();
            }}
          >
            {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
            <form className="form" onSubmit={handleSubmit}>
              <Box>
                <Box className="inputField" style={{boxShadow: errors && errors.email && submitCount > 0 || this.state.emailVerificationFailed ? "0px 0px 3px 0px #C5475B" : ''}}>
                  <p className="inputTitle">Email</p>
                  <InputField
                  data-test-id="emailField"
                  name="email"
                  fullWidth={true}
                  disableUnderline={true}
                  value={values.email}
                  onChange={(e:{target:{value:string}}) => {this.setEmail(e.target.value); setFieldValue("email", e.target.value); setErrors({...errors, email:""}); this.clearForgotStatus()}}
                  />
                </Box>
              </Box>
              <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                <Box style={{width: "100%", height:"235px", display:"flex", flexDirection:"column-reverse"}}>
                {( errors && errors.email && errors.email.includes("required") && submitCount > 0) && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Missing field.</p></div>}
                {errors && errors.email && errors.email.includes("Invalid email format") && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid credentials.</p></div>}
                {this.state.emailVerificationFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.emailVerificationMessage}</p></div>}
                </Box>
                <Button data-test-id="btnSendLink" type="submit" className="signButton">Send link</Button>
                <Box style={{height: "80px"}}>
                  { this.state.linkSent && <>
                    <p className="sentText">A password reset link has been sent to your email.</p>
                    <p className="resend" data-test-id="resendLink" onClick={this.emailVerificationHandler}>Link not received? <span>Resend</span></p>
                  </>}
                </Box>
              </Box>
            </form>
            )}
            </Formik>}
            { this.state.enableResetPassword &&
            <Formik
              initialValues={{
                passcodeInputField: this.state.password,
                confirmPasscodeInputField: this.state.confirmPassword,
              }}
              data-test-id="formikPassword"
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, { setSubmitting, setErrors }) => {  
                const errors:{ passcodeInputField?:string, confirmPasscodeInputField?: string, passcodeMustMatch?: string} = {};

                if (!values.passcodeInputField) {
                  errors.passcodeInputField = configJSON.passcodeRequired;
                }
                if (!values.confirmPasscodeInputField){
                  errors.confirmPasscodeInputField = configJSON.confirmPasswordRequired;
                }
                
                if (values.passcodeInputField !== values.confirmPasscodeInputField && Object.keys(errors).length === 0){
                  errors.passcodeMustMatch = configJSON.passcodeMustMatch;
                }

                if (Object.keys(errors).length > 0) {
                  setErrors(errors);
                  setSubmitting(false);
                  return ;
                }               
                this.resetPasswordHandler();
              }}
            >
              {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
              <form className="form" onSubmit={handleSubmit}>
                <Box>
                  <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.passcodeInputField && submitCount > 0 || (errors as any).passcodeMustMatch ? "0px 0px 3px 0px #C5475B" : ''}}>
                    <p className="inputTitle">New password</p>
                    <InputField
                      data-test-id="passwordField"
                      value={values.passcodeInputField} disableUnderline={true}
                      name="passcodeInputField" fullWidth={true}
                      type={this.state.enablePasswordField ? "password" : "text"}
                      onChange={(e:{target:{value:string}}) => {this.setPassword(e.target.value); setFieldValue("passcodeInputField", e.target.value); setErrors({...errors, passcodeInputField:"", passcodeMustMatch:""} as any); this.clearForgotStatus()}}
                    />
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={this.handleClickShowPassword}
                      data-test-id="hidePassword"
                      style={{ position: "absolute", top: 0, 
                        bottom: 0, right: "14px", 
                        cursor: "pointer"}}
                    >
                    {!this.state.enablePasswordField ? (
                        <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                      ) : (
                        <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                      )}
                    </IconButton>
                    </Box>
                  <Box className="inputField" style={{ paddingRight: "50px", boxShadow: errors && errors.confirmPasscodeInputField && submitCount > 0 || (errors as any).passcodeMustMatch ? "0px 0px 3px 0px #C5475B" : '', marginTop: "20px", }}>
                    <p className="inputTitle">Confirm password</p>
                    <InputField
                      data-test-id="confirmPasswordField"
                      type={this.state.enableConfirmPasswordField ? "password" : "text"}
                      name="confirmPasscodeInputField"
                      disableUnderline={true}
                      fullWidth={true}
                      value={values.confirmPasscodeInputField}
                      onChange={(e:{target:{value:string}}) => {this.setConfirmPassword(e.target.value); setFieldValue("confirmPasscodeInputField", e.target.value); setErrors({...errors, confirmPasscodeInputField:"", passcodeMustMatch:""} as any); this.clearForgotStatus();}}
                    />
                    <IconButton
                      data-test-id="hideConfirmPassword"
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={this.handleClickShowConfirmPassword}
                      style={{ top: 0, bottom: 0, position: "absolute", right: "14px", cursor: "pointer"}}
                    >
                      {!this.state.enableConfirmPasswordField ? (
                        <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                      ) : (
                        <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                      )}
                    </IconButton>
                  </Box>
                </Box>
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                <Box style={{width: "100%", height:"161px", display:"flex", flexDirection:"column-reverse"}}>
                  {( errors && 
                    (errors.passcodeInputField && errors.passcodeInputField.includes("required") || 
                    errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required"))) && submitCount > 0 && 
                    <div className="errorMessage"><ReportProblemOutlinedIcon/> 
                    <p>{errors && 
                      [errors.passcodeInputField && errors.passcodeInputField.includes("required"),
                        errors.confirmPasscodeInputField && errors.confirmPasscodeInputField.includes("required")
                      ].filter(Boolean).length >= 2  ? "Missing fields." : "Missing field."}</p></div>}
                  {errors && ((errors as any).passcodeMustMatch && (errors as any).passcodeMustMatch.includes("passcodeInputField must match")) && submitCount > 0 && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Passwords do not match.</p></div>}
                  {this.state.emailVerificationFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.emailVerificationMessage}</p></div>}
                  </Box>
                  <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Submit</Button>
                  <Box style={{height: "80px", display:"flex"}}>
                    <p className="resend" data-test-id="redirectToSignIn" style={{ marginTop: "auto"}} onClick={this.redirectToSignIn}>Return to <span>Sign in</span></p>
                </Box>
                </Box>
              </form>
              )}
            </Formik>}
          </Box>
        </Box>
      </SignupContainer>
     </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 

const SignupContainer = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box",
    '& .leftContainer':{
      width: "100%",
      height: "100%",
    },
    '& .rightContainer':{
      display: "flex",
      flexDirection: "column",
      padding: "26px"
    },
    '& .sleekText':{
      margin: "0",
      textAlign: "end"
    },
    '& .formContainer':{
      flexGrow: 1,
      width: "418px", 
      display: "flex",
      marginTop: "47px",
      marginLeft: "18px",
      marginRight: "50px",
      marginBottom: "69px",
      borderRadius: "30px",
      flexDirection:"column",
      boxSizing: "border-box",
      padding: "30px 40px 25px",
      backgroundColor: "#ffffff",
      boxShadow: "-2px 10px 19px 0px #D5D5DC, 2px 0px 16px 0px #EEEEF2",
    },
    '& .form':{
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "0"
    },
    '& .formTitle':{
      fontSize: "36px",
      fontWeight: 275,
      lineHeight: "49.18px",
      textAlign: "left",
      color: "#4F506D",
      marginTop: "31px",
      marginBottom: "57px",
      fontFamily: "Avenir Next Ultra Light"
    },
    '& .inputField':{
        padding: "6px 16px",
        position: "relative",
        borderRadius: "20px",
        boxShadow: "0px 1px 3px 0px #72749D4F",
      '& .MuiSvgIcon-root':{
        height: "18px",
        width: "18px"
      },
      '& .MuiIconButton-root':{
        marginTop: "auto",
        marginBottom: "auto",
        height: "fit-content",
      }
    },
    '& .inputTitle':{
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: 300,
      color: "#ADB1C8",
      margin: 0,
      textAlign: "left",
      fontFamily: "Avenir Light"
    },
    '& .signButton':{ 
      padding: "16px",
      width: "100%", 
      borderRadius: "12px", 
      textTransform: "none", 
      color: "#FFFFFF",
      fontSize: "18px",
      fontWeight: "350px",
      textAlign: "center",
      backgroundColor: "#4F506D",
      lineHeight: "24px",
      fontFamily: "Avenir Next Regular",
      cursor: "pointer",
      '& .MuiButton-root':{
        fontFamily: "inherit",
      }
    },
    '& .rotated-icon': {
      transform: "rotateY(180deg)",
    },
    '& .errorMessage':{
      display: "flex",
      fontSize: "12px",
      textAlign: "left",
      color: "#C5475B",
      fontWeight: 400,
      marginRight: "auto",
      marginBottom: "16px",
      fontFamily: "Avenir Next Regular",
      '& p':{
        marginLeft: "5px",
        height: "14px",
        margin: 0,
      },
      '& .MuiSvgIcon-root':{
        height: "14px",
        width: "16px"
      }
    },
    '& .resend':{
      margin: "0",
      fontWeight: 300,
      fontSize: "16px",
      letterSpacing: "0.10000000149011612px",
      lineHeight: "19px",
      cursor: "pointer",
      textAlign: "center",
      color: "#595959",
      fontFamily: "Avenir Light",
      '& span':{
        fontWeight: 500,
        color: "#000000",
        fontFamily: "Avenir Next Medium",
      }
    },
    '& .sentText':{
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "9px",
      letterSpacing: "-0.10000000149011612px",
      textAlign: "center",
      color: "#BABDD4",
      marginTop: "20px",
      marginBottom: "32px",
      fontFamily: "Avenir Next Medium",
    }
})
  
  const InputField = styled(Input)({
    width: "100%",
    padding: "0",
    margin: "0",
    borderRadius: "20px",
    border: "none",
    backgroundColor: "#FFFFFF",
    fontFamily: "Avenir Light",
    fontSize: "16px",
    color: "#4F506D",
    '& .MuiInputBase-input':{
      padding: 0,
      marginBottom: "1px"
    }
  })

// Customizable Area End