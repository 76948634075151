import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";

import {
  Box,
  Button,
  Input,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { carousel_display, sleek } from "./assets";
import * as Yup from "yup";
import { Formik } from "formik";

const SignupContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  boxSizing: "border-box",
  '& .leftContainer':{
    width: "100%",
    height: "100%",
  },
  '& .rightContainer':{
    display: "flex",
    flexDirection: "column",
    padding: "26px"
  },
  '& .sleekText':{
    margin: "0",
    textAlign: "end"
  },
  '& .formContainer':{
    marginLeft: "18px",
    marginRight: "50px",
    marginTop: "47px",
    padding: "30px 40px 25px",
    flexGrow: 1,
    marginBottom: "69px",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection:"column",
    borderRadius: "30px",
    boxShadow: "-2px 10px 19px 0px #D5D5DC, 2px 0px 16px 0px #EEEEF2",
    width: "418px", 
    boxSizing: "border-box"
  },
  '& .form':{
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "0"
  },
  '& .formTitle':{
    fontSize: "36px",
    fontWeight: 275,
    lineHeight: "49px",
    textAlign: "left",
    color: "#4F506D",
    marginBottom: "57px",
    marginTop: "31px",
    fontFamily: "Avenir Next Ultra Light"
  },
  '& .inputField':{
    boxShadow: "0px 1px 3px 0px #72749D4F",
    borderRadius: "20px",
    padding: "6px 16px",
    position: "relative",
    '& .MuiSvgIcon-root':{
      width: "18px",
      height: "18px"
    },
    '& .MuiIconButton-root':{
      height: "fit-content",
      marginTop: "auto",
      marginBottom: "auto"
    }
  },
  '& .inputTitle':{
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "22px",
    textAlign: "left",
    color: "#ADB1C8",
    margin: 0,
    fontFamily: "Avenir Light"
  },
  '& .signButton':{ 
    width: "100%", 
    padding: "16px",
    textTransform: "none", 
    borderRadius: "12px", 
    backgroundColor: "#4F506D",
    color: "#FFFFFF",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    fontWeight: "350px",
    cursor: "pointer",
    fontFamily: "Avenir Next Regular",
    '& .MuiButton-root':{
      fontFamily: "inherit"
    }
  },
  '& .rotated-icon': {
    transform: "rotateY(180deg)"
  },
  '& .errorMessage':{
    fontSize: "12px",
    fontWeight: 400,
    textAlign: "left",
    color: "#C5475B",
    display: "flex",
    marginBottom: "16px",
    marginRight: "auto",
    fontFamily: "Avenir Next Regular",
    '& p':{
      margin: 0,
      marginLeft: "5px",
      height: "14px",
    },
    '& .MuiSvgIcon-root':{
      height: "14px",
      width: "16px" 
    }
  },
  '& .newUser':{
    margin: "0",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "19px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "center",
    color: "#595959",
    cursor: "pointer",
    '& span':{
      color: "#000000",
      fontWeight: 500
    }
  }
})

const InputField = styled(Input)({
  width: "100%",
  padding: "0",
  margin: "0",
  borderRadius: "20px",
  border: "none",
  backgroundColor: "#FFFFFF",
  fontFamily: "Avenir Light",
  fontSize: "16px",
  color: "#4F506D",
  '& .MuiInputBase-input':{
    padding: 0,
    marginBottom: "1px"
  }
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
        <SignupContainer>
          <Box className="leftContainer">
              <img src={carousel_display.default} style={{height: "100%", width: "100%"}}/>
          </Box>
          <Box className="rightContainer">
            <img src={sleek.default} style={{ width: "81px", height: "27px", marginLeft: "auto"}}/>
            <Box className="formContainer">
              <p className="formTitle">Welcome back</p>
              <Formik
              initialValues={{
                passcodeInputField: this.state.password,
                email: this.state.email,
              }}
              data-test-id="formik"
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, { setSubmitting, setErrors }) => {  
                const errors:{ email?:string, passcodeInputField?:string} = {};

                if (!values.email) {
                  errors.email = "Email is required";
                } 
                if (!values.passcodeInputField) {
                  errors.passcodeInputField = "Input Field is required";
                }
                
                if (!Yup.string().email().isValidSync(values.email) && Object.keys(errors).length === 0) {
                  errors.email = "Invalid email format";
                }

                if (Object.keys(errors).length > 0) {
                  setErrors(errors);
                  setSubmitting(false);
                  return ;
                }    

                this.submitHandler();
              }}
            >
              {({ values, errors, submitCount, setFieldValue, handleSubmit, setErrors }) => (
              <form className="form" onSubmit={handleSubmit}>
                <Box>
                  <Box className="inputField" style={{boxShadow: (errors && errors.email && submitCount > 0 || this.state.loginFailed) ? "0px 0px 3px 0px #C5475B" : ''}}>
                    <p className="inputTitle">Email</p>
                    <InputField
                    data-test-id="txtInputEmail"
                    name="email"
                    fullWidth={true}
                    disableUnderline={true}
                    value={values.email}
                    onChange={(e:{target:{value:string}}) => {this.setEmail(e.target.value); setFieldValue("email", e.target.value); setErrors({...errors, email:""}); this.clearLoginStatus()}}
                    />
                  </Box>
                  <Box className="inputField" style={{ paddingRight: "50px", marginTop: "20px", boxShadow: (errors && errors.passcodeInputField && submitCount > 0 || this.state.loginFailed) ? "0px 0px 3px 0px #C5475B" : ''}}>
                    <p className="inputTitle">Password</p>
                    <InputField
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    name="passcodeInputField"
                    fullWidth={true}
                    disableUnderline={true}
                    value={values.passcodeInputField}
                    onChange={(e:{target:{value:string}}) => {this.setPassword(e.target.value); setFieldValue("passcodeInputField", e.target.value); setErrors({...errors, passcodeInputField: ""}); this.clearLoginStatus()}}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    data-test-id="hidePassword"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                    style={{ position: "absolute", top: 0, bottom: 0, right: "14px", cursor: "pointer"}}
                  >
                    {!this.state.enablePasswordField ? (
                      <VisibilityOutlinedIcon style={{color: "#a2a2b1"}}/>
                    ) : (
                      <VisibilityOffOutlinedIcon className="rotated-icon" style={{color: "#a2a2b1"}}/>
                    )}
                  </IconButton>
                  </Box>
                  <p data-test-id={"btnForgotPassword"} style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "10px",
                    textAlign: "right",
                    color: "#B9BDD3",
                    cursor: "pointer",
                    width: "fit-content",
                    marginLeft: "auto",
                    marginTop: "24px",
                    marginBottom: 0,
                    fontFamily: "Avenir Next Medium"
                    }} onClick={() => this.goToForgotPassword()} >Forgot your password?</p>
                </Box>
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                  <Box style={{width: "100%", height:"127px", display:"flex", flexDirection:"column-reverse"}}>
                  {( errors && (
                    errors.email && errors.email.includes("required") || 
                    errors.passcodeInputField && errors.passcodeInputField.includes("required")) && submitCount > 0) && 
                    <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{errors && (errors.email && errors.email.includes("required") && errors.passcodeInputField && errors.passcodeInputField.includes("required")) ? "Missing fields." : "Missing field."}</p></div>}
                  {errors && errors.email && errors.email.includes("Invalid email format") && submitCount > 0 && !this.state.loginFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>Invalid credentials.</p></div>}
                  {this.state.loginFailed && <div className="errorMessage"><ReportProblemOutlinedIcon/> <p>{this.state.loginFailedMessage}</p></div>}
                  </Box>
                  <Button data-test-id="btnEmailLogIn" type="submit" className="signButton">Sign In</Button>
                  <Box style={{height: "80px", display:"flex"}}>
                    <p style={{margin: 0,
                        marginTop: "20px",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "10px",
                        textAlign: "left",
                        letterSpacing: "-0.10000000149011612px",
                        color: "#BABDD4",
                        cursor: "pointer",
                        fontFamily: "Avenir Next Medium",
                        }}>By proceeding, you agree to our <span style={{
                        fontSize: "13px",
                        fontWeight: 500,
                        textAlign: "left",
                        color: "#72749D",
                        letterSpacing: "-0.25px"
                        }}>Terms and Conditions.</span></p>
                  </Box>
                </Box>
              </form>
              )}
              </Formik>
            </Box>
          </Box>
        </SignupContainer>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
