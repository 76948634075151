import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
} from "@material-ui/core";

import InteractivefaqsSharedController, {Props,configJSON} from "./InteractivefaqsSharedController";
import { background_image, back_button, sleek, carouselImage, } from "./assets";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InvalidModal from "./components/InvalidModal.web";

// Customizable Area End

export default class Interactivefaqs extends InteractivefaqsSharedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start
  // Customizable Area End
  render() {

    if(this.state.renderInvalidScreen)
    {
        return (<InvalidModal carouselImage={carouselImage.default} sleekImage={sleek.default} heading={"Oops!"} description={"Unable to load page information."} firstButtonTitle={"Home"} secondButtonTitle={"Support"} />)
    }

    return (
       // Customizable Area Start
     <>
      <FaqContainer>
        <Box>
            <Box className="header">
                <img src={background_image.default} style={{height: "100%", width: "100%", position: "absolute", left:0, top:0, zIndex:"-2"}}/>
                <Box className="headerMenu">
                    <p>pricing</p> <p>contact</p> <p>members</p> <img src={back_button.default} style={{ cursor: "pointer" }}/>
                </Box>
                <PlayArrowIcon className="playButton"/>
                <p className="heading">Frequently Asked Questions</p>
            </Box>
        </Box>
        <Box className="bodyContainer">
             <Box className="leftContainer">
                <p className="selected">General</p>
                <p data-test-id="dummyInvalidModal" onClick={this.dummyfunctionToTestInvalidModal}>Subscription</p>
                <p>Pricing</p>
                <p>Privacy</p>
             </Box>
             <Box className="rightContainer">
                <Box className="accordionContainer">
                    <Accordion data-test-id="dummyAccordianTest" expanded={this.state.isExpanded === 'panel1'} onChange={this.handleAccordionChange('panel1')}>
                        <AccordionSummary
                        expandIcon={<div className="expandContainer"><ExpandMoreIcon className={this.state.isExpanded === 'panel1' ? "expandableIcon" : ""} /></div>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className="accordionTitle">What is sleek?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="accordionContent">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion data-test-id="dummyAccordianTest" expanded={this.state.isExpanded === 'panel2'} onChange={this.handleAccordionChange('panel2')}>
                        <AccordionSummary
                        expandIcon={<div className="expandContainer"><ExpandMoreIcon className={this.state.isExpanded === 'panel2' ? "expandableIcon" : ""}/></div>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className="accordionTitle">What it’s not.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="accordionContent">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion data-test-id="dummyAccordianTest" expanded={this.state.isExpanded === 'panel3'} onChange={this.handleAccordionChange('panel3')}>
                    <AccordionSummary
                    expandIcon={<div className="expandContainer"><ExpandMoreIcon className={this.state.isExpanded === 'panel3' ? "expandableIcon" : ""}/></div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="accordionTitle">Is it for you?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordionContent">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                        sit amet blandit leo lobortis eget.
                    </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion data-test-id="dummyAccordianTest" expanded={this.state.isExpanded === 'panel4'} onChange={this.handleAccordionChange('panel4')}>
                        <AccordionSummary
                        expandIcon={<div className="expandContainer"><ExpandMoreIcon className={this.state.isExpanded === 'panel4' ? "expandableIcon" : ""}/></div>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className="accordionTitle">Why choose Sleek?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography className="accordionContent">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box className="sleekContainer">
                    <img src={sleek.default} />
                    <p>For support, email us at <span>care@sleek.com.</span></p>
                </Box>
             </Box>
        </Box>
      </FaqContainer>
     </>
       // Customizable Area End
    );
  }
}
// Customizable Area Start 

const FaqContainer = styled(Box)({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    '& .header':{
        height: "246px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        zIndex: 7,
        padding: "35px",
        paddingBottom: "28px",
        boxSizing: "border-box",
        '& .heading':{
            fontSize: "31px",
            fontWeight: "400",
            lineHeight: "42.35px",
            letterSpacing: "-0.01em",
            textAlign: "left",
            color: "#FFFFFF",
            margin: 0,
            marginTop: "auto",
            fontFamily: "Avenir Next Regular"
        }
    },
    '& .headerMenu':{
        display: "flex",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "26px",
        letterSpacing: "0.04em",
        color: "#FFFFFF",
        marginLeft: "auto",
        width: "fit-content",
        gap: "38px",
        '& p':{
            margin:0,
            cursor: "pointer",
            fontFamily: "Avenir Next Medium",
            fontSize: "18px"
        }
    },
    '& .playButton':{
        color: "#FFFFFF4D",
        cursor: "pointer",
        top: "0",
        bottom: "0",
        marginTop: "auto",
        marginBottom: "auto",
        paddingTop: "40px",
        position: "absolute",
        right: "77px",
    },
    '& .playButton.MuiSvgIcon-root':{
        width: "90px",
        height: "90px"
    },
    '& .bodyContainer':{
        display:"flex",
        flexGrow: 1,
        marginTop: "62px",
        '& .leftContainer':{
            padding: "0 72px",
            gap: "30px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "70px"
        },
        '& .leftContainer p':{
            width: "155px",
            height: "80px",
            color: "#B3BFDB",
            borderRadius: "76px",
            boxShadow: "-2px 8px 21px 0px #8E90C082",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            fontSize: "18px",
            fontWeight: "300",
            lineHeight: "26px",
            textAlign: "center",
            cursor: "pointer",
            fontFamily: "Avenir Light"
        },
        '& .leftContainer p:hover':{
            boxShadow: "-2px 8px 21px 0px #8E90C082",
            color: "#4F506D",
            fontWeight: "500",
            fontFamily: "Avenir Next Medium"
        },
        '& .selected':{
            backgroundColor: "#F4F4F9 !important",
            color: "#4F506D !important",
            boxShadow: "2px 5px 9px 0px #D2D5EBEB !important",
            fontWeight: "500 !important",
            fontFamily: "Avenir Next Medium !important"
        },
        '& .rightContainer':{
            width: "100%",
            zIndex: 2,
            paddingRight: "40px",
            paddingLeft: "32px",
            paddingBottom: "20px",
            height: "calc(100vh - 308px)",
            minHeight: "100%",
            boxSizing: "border-box",
            '& ::-webkit-scrollbar': {
                width: "10px",
                borderRadius: "40px"
              },
            "& ::-webkit-scrollbar-track": {
                backgroundColor: "#F0F2F8",
                borderRadius: "40px"
            },
            "& ::-webkit-scrollbar-thumb":{
                width: "8px",
                backgroundColor: "#B3BFDB",
                borderRadius: "40px",
            },
            '& .accordionContainer':{
                display: "flex",
                flexDirection: "column",
                gap: "55px",
                height: "calc(100% - 82px)",
                overflow: "auto"
            },
            "*":{
            scrollbarWidth: "thin",
            scrollbarColor: "#B3BFDB #F0F2F8"
            },
            '& .MuiAccordionSummary-root':{
                flexDirection: "row-reverse",
                cursor: "pointer"
            },
            '& .MuiAccordion-root::before':{
                backgroundColor: "transparent"
            },
            '& .MuiPaper-elevation1':{
                border: "none",
                boxShadow: "none"
            },
            '& .accordionTitle':{
                fontSize: "30px",
                fontWeight: 300,
                color: "#4F506D",
                lineHeight: "40.98px",
                fontFamily: "Avenir Light"
            },
            '& .accordionContent':{
                fontFamily: "Avenir Light",
                fontSize: "18px",
                fontWeight: 300,
                lineHeight: "26px",
                color: "#4F506D",
                paddingLeft: "54px"
            },
            '& .MuiSvgIcon-root':{
                width: "16px",
                height: "16px",
                color: "#4F506D"
            },
            '& .MuiAccordionSummary-content':{
                margin: 0,
                marginLeft: "26px"
            },
            '& .MuiAccordion-root.Mui-expanded':{
                margin: 0
            },
            '& .MuiAccordionSummary-root.Mui-expanded':{
                minHeight: "48px"
            },
            '& .expandableIcon.MuiSvgIcon-root':{
                width: "24px",
                height: "24px"
            },
            '& .MuiIconButton-root':{
                padding: "0"
            },
            '& .expandContainer':{
                width: "40px", 
                height:"40px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center"
            }
        },
    },
    '& .sleekContainer':{
        marginLeft: "auto",
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
        '& img':{
          marginLeft: "auto"  
        },
        '& p':{
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "24px",
            color: "#B3BFDB",
            marginBottom: 0
        },
        '& span':{
            color: "#4F506D"
        }
    }
});

// Customizable Area End